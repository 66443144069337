import React from "react";
import { Container, Row, Col } from "react-grid-system";
import { useStaticQuery, graphql } from "gatsby";

import TrainingsList from "Components/Trainings/TrainingsList";
import useLang from "Commons/Hooks/useLang";
import InternalPage from "Commons/InternalPage";

import "./styles.scss";

const InternalTrainingPage = ({
  title = "",
  richText = "",
  options = {},
  currentTraining = "",
}) => {
  const [lang] = useLang();
  const data = useStaticQuery(graphql`
    query getOtherTrainingTexts {
      allContentfulTrainingTexts {
        nodes {
          otherTrainingsText
        }
      }
    }
  `);

  const trainingTexts = data.allContentfulTrainingTexts.nodes[lang];

  return (
    <>
      <InternalPage title={title} richText={richText} />
      <div className="other-trainings">
        <Container>
          <Row>
            <Col xs={12} md={4} className="other-trainings__title">
              <span>{trainingTexts.otherTrainingsText}</span>
            </Col>
            <Col xs={12} md={8}>
              <TrainingsList currentTraining={currentTraining} />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default InternalTrainingPage;
