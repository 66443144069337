import React from "react";
import { graphql } from "gatsby";
import Header from "Commons/Header";
import Footer from "Commons/Footer";
import InternalTrainingPage from "Components/Trainings/InternalTrainingPage";
import { Helmet } from "react-helmet";
import useLang from "Commons/Hooks/useLang";

const DominatingBurpSuitePage = ({ data, location }) => {
  const [lang] = useLang();
  const {
    dominatingBurpSuitePageTitle,
    dominatingBurpSuiteTitle,
    dominatingBurpSuiteDescription,
  } = data.allContentfulTrainingTexts.nodes[lang];
  return (
    <>
      <Helmet>
        <title>{dominatingBurpSuitePageTitle}</title>
      </Helmet>
      <Header noScrollEffect />
      <InternalTrainingPage
        title={dominatingBurpSuiteTitle}
        richText={dominatingBurpSuiteDescription}
        currentTraining={location.pathname}
      />
      <Footer />
    </>
  );
};

export const query = graphql`
  query getDominatingBurpSuitePageText {
    allContentfulTrainingTexts {
      nodes {
        dominatingBurpSuitePageTitle
        appsecForDevelopersTitle
        appsecForDevelopersSynopsis {
          appsecForDevelopersSynopsis
        }
        webHackingTitle
        webHackingSynopsis {
          webHackingSynopsis
        }
        dominatingBurpSuiteTitle
        dominatingBurpSuiteDescription {
          raw
        }
      }
    }
  }
`;

export default DominatingBurpSuitePage;
